<template>
  <div>
    <div class="file-container" @click="onPress">
      <div class="file-info">
        <md-icon v-if="!this.file.contentType.startsWith('image')">{{ getIcon() }}</md-icon>
        <md-avatar class="square-avatar" v-if="this.file.contentType.startsWith('image')">
          <img class="placeholder-img" v-if="placeholderImage" :src="placeholderImage"  alt="placeholder">
        </md-avatar>
        <p class="filename">{{ file.displayName }}</p>
      </div>
      <div class="file-actions">
        <p v-if="!file.isFolder">{{ sizeToString(file.size) }}b</p>
        <md-icon v-if="!file.isFolder && canDownload">download</md-icon>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import HTTP from "../services/axios-default";

export default {
  components: {},
  props: {
    file: {
      type: Object,
    },
    onSelected: {
      type: Function,
    }
  },
  data() {
    return {
      placeholderImage: null,
      imageBlob: null,
    }
  },
  mounted() {
    if (this.file.contentType.startsWith('image')) {
      this.getImage();
    }
  },
  methods: {
    onPress() {
      if (this.file.isFolder) {
        this.$emit('did-press', this.file.directory + this.file.name + '/');
      } else {
        if (this.file.contentType.startsWith('image')) {
          const newTab = window.open(this.placeholderImage, '_blank');
          if (newTab) {
            newTab.focus();
          } else {
            alert('Your browser blocked opening a new tab. Please check your browser settings.');
          }
        } else {
          this.onSelected(this.file);
        }
      }
    },

    async getImage() {
      try {
        const response = await HTTP.get('organization/storage/' + this.file.id, {responseType: 'blob'}).catch(console.error);
        console.log(contentType);
        this.imageBlob = response.data;
        const contentType = response.headers['content-type']
        const imageUrl = URL.createObjectURL(new Blob([response.data], {type: contentType}));
        this.placeholderImage = imageUrl;
      } catch (error) {
        console.error('Error fetching image:', error);
      }

    },
    getIcon() {
      if (this.file && this.file.isFolder) {
        return 'folder';
      }
      if (this.file && this.file.contentType) {
        if (this.file.contentType.startsWith('image')) {
          return 'image'
        } else if (this.file.contentType.startsWith('video')) {
          return 'play_arrow';
        } else if (this.file.contentType.startsWith('folder')) {
          return 'folder';
        } else {
          return 'article';
        }
      }
    },
    sizeToString(size) {
      try {
        size = parseInt(size);
        if (size > 1000000000) {
          return (size / 1000000000).toFixed(1) + " g";
        }
        if (size > 1000000) {
          return (size / 1000000).toFixed(1) + " m";
        }
        if (size > 1000) {
          return (size / 1000).toFixed(1) + " k";
        }
        return size.toFixed(1) + " b";
      } catch(e) {
        return '';
      }
    },
  },
  computed: {
    canDownload() {
      return !(this.file.contentType.startsWith('image') || this.file.contentType.startsWith('video'));
    },
  },

}
</script>
<style lang="css">
.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
}
.file-info .md-icon {
  margin-right: 8px;
}
.file-actions {
  display: flex;
  align-items: center;
}
.file-actions .md-icon {
  margin-left: 8px;
}
p {
  font-family: 'Inter';
}
.file-container .file-info .filename {
  margin: auto;
  margin-left: 8px;
}

.file-container .file-info .foldername {
  margin: auto;
  font-weight: bold;
}
.md-card img {
  height: 100% !important;
}
.square-avatar {
  border-radius: 8px !important;
}
.placeholder-img {
  object-fit: cover;
}
</style>