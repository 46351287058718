<template>
  <div class="wrapper">
    <div class="main">
      <div class="section">
        <div class="container">
          <div class="md-layout md-gutter md-alignment mt-0">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-card>
                <Ticker :numb="getData && getData.total ? getData.total : 0" :title="'Antal lyssningar'"
                        :description="'Så här många gånger har användare lyssnat på era storyspots sedan de laddades upp. Detta gäller alla storyspots.'"></Ticker>
              </md-card>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-card>
                <DoughnutChart :data="getDoughnutData"/>
              </md-card>
            </div>
          </div>
          <div class="md-layout md-gutter mt-0">
            <div class="md-layout-item md-size-100 md-small-size-100 small mt-0">
              <div class="buttons-container">
                <md-button :class="{'md-primary': selectedTimeFrame === 'YEAR'}" @click="selectTimeFrame('YEAR')">
                  {{ 'År' }}
                </md-button>
                <md-button :class="{'md-primary': selectedTimeFrame === 'MONTH'}" @click="selectTimeFrame('MONTH')">
                  {{ 'Månad' }}
                </md-button>
              <!--  <md-button :class="{'md-primary': selectedTimeFrame === 'WEEK'}" @click="selectTimeFrame('WEEK')">
                  {{ 'Vecka' }}
                </md-button> -->
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mt-0">
              <md-card>
                <LineGraph :data="getLineChart"/>
              </md-card>
            </div>
          </div>
          <div class="md-layout md-gutter mt-0">
            <div class="md-layout-item md-size-100">
              <TopStoryspots :data="getTopSpots"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import sharedConfig from "../../../shared/shared-config";
import Ticker from "../components/new-analytics/Ticker";
import DoughnutChart from "../components/new-analytics/DoughnutChart";
import LineGraph from "../components/new-analytics/LineGraph";
import TopStoryspots from "../components/new-analytics/TopStoryspots";
import {getAllDaysFromPeriod} from "../../../shared/utils/stat-util";
import {colors} from "../utils/contants";


export default {
  bodyClass: "dashboard",
  components: {
    Ticker,
    DoughnutChart,
    LineGraph,
    TopStoryspots
  },
  created() {
    document.title = this.getOrgName ? this.getOrgName + " - Organization" : 'Storyspot - Organization';
  },
  data() {
    return {
      isLoading: null,
      selectedTimeFrame: 'YEAR',
    }
  },
  methods: {
    selectTimeFrame(time) {
      this.selectedTimeFrame = time;
    }
  },
  mounted() {
    if (!this.$store.getters.hasLoadedStats) {
      this.isLoading = true;
      this.$store.dispatch(sharedConfig.store.analytics.GET_STATS).then(() => {
        this.isLoading = false;
      });

    }
  },
  computed: {
    hasLoadedStats() {
      return this.$store.getters.hasLoadedStats;
    },
    getData() {
      return this.$store.getters.getDashboardData;
    },
    getDoughnutData() {
      if (this.$store.getters.getDashboardData && this.$store.getters.getDashboardData.byCity) {
        let data = {}
        for (let i = 0; i < Object.keys(this.$store.getters.getDashboardData.byCity).length; i++) {
          let key = Object.keys(this.$store.getters.getDashboardData.byCity)[i];
          let value = Object.values(this.$store.getters.getDashboardData.byCity)[i].length;
          let percent = (value / this.$store.getters.getDashboardData.total) * 100;
          if (percent >= 2 && key !== "(not set)" && key !== "") {
            data[key] = percent;
          } else {
            if ('Övrigt' in data) {
              data['Övrigt'] += percent;
            } else {
              data['Övrigt'] = percent;
            }
          }
        }

        data = Object.fromEntries(
            Object.entries(data).sort(([, a], [, b]) => b - a)
        );

        return {
          labels: Object.keys(data),
          datasets: [
            {
              backgroundColor: colors,
              data: Object.values(data).map((a) => a.toFixed(1)),
            }
          ]
        };
      } else {
        return null;
      }
    },
    getLineChart() {
      if (this.$store.getters.getDashboardData && this.$store.getters.getDashboardData.byDay) {
        let date = new Date();
        if (this.selectedTimeFrame === 'YEAR') {
          date.setFullYear(date.getFullYear() - 1);
        } else if (this.selectedTimeFrame === 'MONTH') {
          date.setMonth(date.getMonth()-1);
        } else if (this.selectedTimeFrame === 'WEEK') {
          date.setDate(date.getDate()-7);
        }
        let labels = getAllDaysFromPeriod(date, new Date());
        let tempV = this.$store.getters.getDashboardData.byDay;
        let values = [];
        for (let i = 0; i < labels.length; i++) {
          let date = labels[i];
          let filter = Object.keys(tempV).filter((a) => new Date(a).toLocaleDateString() === date);
          if (!filter || filter.length < 1) {
            values.push(0);
          } else {
            values.push(tempV[filter[0]].length);
          }
        }
        return {
          labels: labels,
          datasets: [
            {
              label: 'Lyssningar',
              data: values,
            }
          ]
        };
      } else {
        return null;
      }
    },
    getTopSpots() {
      return this.$store.getters.getMostPopular;
    },
    hasLoadedPopular() {
      return this.$store.getters.hasLoadedPopular;
    },
    getOrg() {
      if (this.$store.getters.getOrganization)
        return this.$store.getters.getOrganization;
      else
        return null;
    },
    getOrgName() {
      return this.getOrg && this.getOrg.profile.fullName ? this.getOrg.profile.fullName : ''
    },

  }
}
</script>

<style scoped>


.section {
  min-height: 80vh !important;
  padding-top: 20px !important;
}

a {
  cursor: pointer;
}

.md-layout-item {
  min-height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.md-layout.md-gutter > .md-layout-item {
  padding-left: 10px;
  padding-right: 10px;
}

.md-card {
  min-height: 320px;
  margin-top: 0;
  margin-bottom: 0;
}

.small {
  min-height: unset !important;
  height: 91px;
}

.md-button {
  height: 41px;
  width: 100px;
  margin-right: 10px;
}

.mt-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.buttons-container {
  margin: auto auto auto 0;
}


</style>