<template>
  <div>
  <div class="upload-container">
    <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
      <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
      />

      <label v-if="!(files && files.length > 0)" for="fileInput" class="file-label">
        <div v-if="isDragging">Dra och släpp filer här.</div>
        <div v-else>Släpp filer här eller <u>klicka här</u> för att ladda upp.</div>
      </label>

      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <ul>
            <li class="list-item">
                <img class="preview-img" :src="generateThumbnail(file)"/>
              <button
                  style="margin-left: 5px; float: right;"
                  type="button"
                  @click="remove(files.indexOf(file))"
                  title="Remove file"
              >
                <b>&times;</b>
              </button>
                <p :title="file.name" style="margin-left: 5px; float: right;">{{ makeName(file.name) }}</p>
            </li>
          </ul>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
    <md-button v-if="files && files.length > 0" class="md-primary" @click="onUpload(files)">Ladda upp</md-button>
  </div>
</template>

<script>

export default {
  props: {
    onUpload: {
      type: Function,
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
    },
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    makeName(name) {
      return (
          name.split(".")[0].substring(0, 10) +
          "..." +
          name.split(".")[name.split(".").length - 1]
      );
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-container {
  max-height: 80vh;
}

.dropzone-container {
  background: #f7fafc;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
  min-height: 200px;
  margin: auto;
  padding: 70px 0;
  text-align: center;
}

.preview-container {
  margin-top: 2rem;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}

.preview-card {
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}

ul {
  list-style-type: none;
}

.list-item {
  float: left;
  clear: left;
  margin-bottom: 5px;
}
</style>