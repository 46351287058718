<template>
  <div class="wrapper">
    <div class="main">
      <div class="section">
        <div v-if="isLoading" class="loader md-layout md-gutter md-alignment-center-center">
          <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div class="container text-center">
          <div v-if="!isLoading && storyspotPlace">
            <div class="md-layout md-gutter md-card">
              <div
                  class="md-layout-item md-size-100"
              >
                <ImageSlider class="image-slider" :images="storyspotPlace.storyspot.images"/>
                <h1 style="display: flex; justify-content: center">
                  <div v-if="!this.storyspotPlace.storyspot.isActive" class="not-active">
                    <md-icon class="icon">visibility_off</md-icon>
                    <md-tooltip md-delay="300" md-direction="right">This storyspot is
                      {{ this.storyspotPlace.storyspot.status.name }} and not
                      visible to other users
                    </md-tooltip>
                  </div>
                  {{ storyspotPlace.storyspot.name }}
                </h1>
                <div class="blockquote subheader large-paragraph">
                  <small>
                    <badge :style="`background-color: #c9c9c9; font-size: 14px;`">
                      <md-icon class="md-size-1x" style="display: inline-block; margin-right: 2px;">person</md-icon>
                      {{ getAuthorUsername }}
                    </badge>
                    <badge :style="`background-color: ${hexToRgbA}; font-size: 14px;`">
                      <md-icon class="md-size-1x" :md-src="storyspotPlace.storyspot.category.iconPath"  style="display: inline-block; margin-right: 2px;"/>
                      {{ getCategoryName }}
                    </badge>
                    <a class="map-marker" @click="showMap">
                      <md-icon>map</md-icon>
                    </a>
                  </small>
                </div>
                <div class="height-divider"/>
                <div class="margin-b">
                  <div class="buttons">
                    <div class="mini-list">
                      <p class="large-paragraph">{{ storyspotPlace.storyspot.likes }}
                      <p/>
                      <md-icon style="margin-left: 8px">favorite</md-icon>
                    </div>
                    <md-button class="md-primary md-just-icon md-round big-icon" @click="playAudio">
                      <md-icon>play_arrow</md-icon>
                    </md-button>
                    <div class="dropdown-container">
                      <li class="md-list-item">
                        <a
                            href="javascript:void(0)"
                            class="md-list-item-router md-list-item-container md-button-clean dropdown"
                        >

                          <drop-down direction="down">
                            <md-icon>more_vert</md-icon>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li class="dropdown-header">Alternativ</li>
                              <li>
                                <a class="dropdown-item" @click="copyLink">
                                  <md-icon>link</md-icon>
                                  <p class="small-paragraph">Kopiera länk</p>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" @click="showModal">
                                  <md-icon>qr_code_2</md-icon>
                                  <p class="small-paragraph">Skapa QR kod</p>
                                </a>
                              </li>
                              <!--  <li class="dropdown-header">Settings</li>
                              <li>
                                 <router-link class="dropdown-item" :to="'/edit-storyspot/' + this.storyspotId">
                                   <md-icon>edit</md-icon>
                                   <p class="small-paragraph">Edit storyspot</p>
                                 </router-link>
                               </li> -->
                            </ul>
                          </drop-down>
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="height-divider"/>
                <p class="text-description margin-text text-left large-paragraph">
                  {{ storyspotPlace.storyspot.description }}
                </p>
                <div class="blockquote subheader large-paragraph text-left margin-text">
                  <small>
                    {{ storyspotPlace.storyspot.currentDateString }}
                    <br>
                    {{ storyspotPlace.storyspot.language.name }}
                  </small>
                </div>
                <div class="margin-top" style="padding-bottom: 25px">
                  <p>Taggar:</p>
                  <md-chip class="tag" v-for="tag in storyspotPlace.storyspot.tags" v-bind:key="tag">{{
                      tag
                    }}
                  </md-chip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section stats">
        <div class="container">
          <h3>Statistik för denna storyspot</h3>
          <div class="md-layout md-gutter md-alignment ">
            <div class="md-layout-item md-size-50 md-xsmall-size-100 mt-0">
              <md-card>
                <Ticker
                    :numb="storyspotId && stats ? stats.total : null"
                    :title="'Antal lyssningar'"
                    :description="'Så här många gånger har en användare lyssnat på denna storyspots sedan den laddades upp.'"></Ticker>
              </md-card>
            </div>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-card>
                <DoughnutChart :data="getDoughnutData"/>
              </md-card>
            </div>
          </div>
          <div class="md-layout md-gutter mt-0">
            <div class="md-layout-item md-size-100 md-small-size-100 small mt-0">
              <div class="buttons-container">
                <md-button :class="{'md-primary': selectedTimeFrame === 'YEAR'}" @click="selectTimeFrame('YEAR')">
                  {{ 'År' }}
                </md-button>
                <md-button :class="{'md-primary': selectedTimeFrame === 'MONTH'}" @click="selectTimeFrame('MONTH')">
                  {{ 'Månad' }}
                </md-button>
                <md-button :class="{'md-primary': selectedTimeFrame === 'WEEK'}" @click="selectTimeFrame('WEEK')">
                  {{ 'Vecka' }}
                </md-button>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 mt-0">
              <md-card>
                <LineGraph :data="getLineChart"/>
              </md-card>
            </div>
          </div>
        </div>
      </div>
      <modal v-if="storyspotPlace && mapVisible" @close="closeMap">
        <template slot="header">
          <md-button
              class="md-simple md-just-icon md-round modal-default-button"
              @click="closeMap"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <Map class="map-holder" :sp="storyspotPlace"/>
        </template>
      </modal>
      <QrCode v-if="storyspotPlace" :storyspot="storyspotPlace.storyspot" :show="showQrModal" :close="closeModal"/>
    </div>
  </div>
</template>
<script>
import sharedConfig from "../../../shared/shared-config"
import Badge from "../../../shared/components/Badge";
import ImageSlider from "../../../shared/components/ImageSlider";
import Map from "../../../shared/components/Map";
import Modal from "../../../shared/components/Modal";
import {copyString} from "../../../shared/utils/general-utils";
import Vue from "vue";
import QrCode from "../../../shared/components/QrCode";
import BreadCrumbs from "../../../shared/components/Breadcrumb";
import Ticker from "../components/new-analytics/Ticker";
import {colors} from "../utils/contants";
import {getAllDaysFromPeriod} from "../../../shared/utils/stat-util";
import DoughnutChart from "../components/new-analytics/DoughnutChart";
import LineGraph from "../components/new-analytics/LineGraph";
import router from "../router";
import storyspot from "../store/storyspot";

export default {
  bodyClass: "Detailed-storyspot",
  components: {
    Badge,
    ImageSlider,
    Map,
    Modal,
    QrCode,
    BreadCrumbs,
    Ticker,
    DoughnutChart,
    LineGraph
  },
  data() {
    return {
      storyspotId: null,
      mapVisible: false,
      isLoading: true,
      storyspotPlace: null,
      stats: null,
      isLoadingStats: true,
      error: null,
      showQrModal: false,
      selectedTimeFrame: 'YEAR',
    }
  },
  mounted() {
    this.storyspotId = this.$route.params.id;
    if (this.storyspotId) {
      this.isLoading = true;
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST, this.storyspotId).then((data) => {
        if (data.storyspot.author.id !== this.$store.getters.getOrganization.id) {
          router.replace("/our-storyspots");
        } else {
          this.storyspotPlace = data;
          document.title = this.storyspotPlace.storyspot.name + " - Storyspot";
          this.isLoading = false;
        }
        console.log(this.storyspotPlace.storyspot.category.iconPath);
      });
      this.$store.dispatch(sharedConfig.store.analytics.GET_STORYSPOT_STATS, this.storyspotId).then((stats) => {
        this.isLoadingStats = false;
        this.stats = stats;
      });

    }
  },
  methods: {
    selectTimeFrame(time) {
      this.selectedTimeFrame = time;
    },
    playAudio() {
      this.$store.dispatch(sharedConfig.store.audioPlayer.SET_AUDIO, this.storyspotPlace.storyspot.audio);
    },
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    showMap() {
      this.mapVisible = true;
    },
    closeMap() {
      this.mapVisible = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspotPlace.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Länk kopierad",
        type: "success",
        position: "top-right"
      });
    },
  },
  computed: {
    hexToRgbA() {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(this.storyspotPlace.storyspot.category.color)) {
        c = this.storyspotPlace.storyspot.category.color.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)';
      }
      throw new Error('Bad Hex');
    },
    getCategoryName() {
      if (this.storyspotPlace && this.storyspotPlace.storyspot && this.storyspotPlace.storyspot.category && this.storyspotPlace.storyspot.category.names && this.storyspotPlace.storyspot.category.names.sv) {
        return this.storyspotPlace.storyspot.category.names.sv;
      } else {
        return this.storyspotPlace.storyspot.category.name;
      }
    },
    getAuthorUsername() {
      return this.storyspotPlace ? this.storyspotPlace.storyspot.author.fullName ? this.storyspotPlace.storyspot.author.fullName : this.storyspotPlace.storyspot.author.username : '';
    },
    getStoryspotName() {
      if (this.storyspotPlace && this.storyspotPlace.storyspot && this.storyspotPlace.name) {
        return this.storyspotPlace.storyspot.name;
      }
      return "";
    },
    getStats() {
      return this.$store.dispatch(sharedConfig.store.analytics.GET_STORYSPOT_STATS);
    },
    getDoughnutData() {
      if (this.storyspotId && this.stats) {
        let data = {}
        for (let i = 0; i < Object.keys(this.stats.byCity).length; i++) {
          let key = Object.keys(this.stats.byCity)[i];
          let value = Object.values(this.stats.byCity)[i].length;
          let percent = (value / this.stats.total) * 100;
          if (percent >= 2 && key !== "(not set)" && key !== "") {
            data[key] = percent;
          } else {
            if ('Övrigt' in data) {
              data['Övrigt'] += percent;
            } else {
              data['Övrigt'] = percent;
            }
          }
        }

        data = Object.fromEntries(
            Object.entries(data).sort(([, a], [, b]) => b - a)
        );

        return {
          labels: Object.keys(data),
          datasets: [
            {
              backgroundColor: colors,
              data: Object.values(data).map((a) => a.toFixed(1)),
            }
          ]
        };
      } else {
        return null;
      }
    },
    getLineChart() {
      if (this.storyspotId && this.stats) {
        let date = new Date();
        if (this.selectedTimeFrame === 'YEAR') {
          date.setFullYear(date.getFullYear() - 1);
        } else if (this.selectedTimeFrame === 'MONTH') {
          date.setMonth(date.getMonth() - 1);
        } else if (this.selectedTimeFrame === 'WEEK') {
          date.setDate(date.getDate() - 7);
        }
        let labels = getAllDaysFromPeriod(date, new Date());
        let tempV = this.$store.getters.getDashboardData.byDay;
        let values = [];
        for (let i = 0; i < labels.length; i++) {
          let date = labels[i];
          let filter = Object.keys(tempV).filter((a) => new Date(a).toLocaleDateString() === date);
          if (!filter || filter.length < 1) {
            values.push(0);
          } else {
            values.push(tempV[filter[0]].length);
          }
        }
        return {
          labels: labels,
          datasets: [
            {
              label: 'Lyssningar',
              data: values,
            }
          ]
        };
      } else {
        return null;
      }
    },
  }
}
</script>
<style lang="scss" scoped>

.main {
  min-height: 80vh;
  z-index: 0;
}


.carousel {
  height: 25vh !important;
}

.subheader {
  font-size: 22px;
}

.margin-top {
  margin-top: 50px;
}

.blockquote strong {
  margin: 0 15px 0 15px;
}

.big-icon {
  margin-left: 50px;
  margin-right: 50px;
  width: 80px !important;
  height: 80px !important;
  border-right: 35px !important;
  font-size: 100px !important;
}

.md-button.md-round, .md-button.md-round.md-fab, .md-button.md-round.md-just-icon {
  border-radius: 40px !important;
}

.md-button.md-fab i, .md-button.md-fab, .md-button.md-just-icon i, .md-button.md-just-icon {
  font-size: 30px !important;
}

.margin-text {
  max-width: 600px;
  min-height: 200px;
  padding: 0 6px 0 6px;
  margin: auto;
}

.margin-b {
  max-width: 400px;
  margin: auto;

}

.buttons {
  display: flex;
  justify-content: center;
}

.map-holder {
  height: 50vh !important;
}

.map-marker {
  cursor: pointer;
}

.dropdown-container {
  margin: auto;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.not-active {
  float: left;
  padding-right: 8px
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  margin: auto 12px auto auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-top: 10px;
  }
}

.height-divider {
  height: 50px;
}

.stats .md-layout-item {
  margin-bottom: 30px;
  min-height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats .md-layout.md-gutter > .md-layout-item {
  padding-left: 10px;
  padding-right: 10px;
}

.stats .md-card {
  min-height: 320px;
  margin-top: 0;
  margin-bottom: 0;
}

.stats h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.mt-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.small {
  min-height: unset !important;
  height: 41px;
}

.buttons-container {
  margin: auto auto 0 0;
}

.cat-icon {
  font-size: 10px !important;
}
</style>