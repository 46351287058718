<template>
  <div>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <div class="footer-media">
      <div class="social-media">
        <p class="intro-text">Håll dig uppdaterad via våra sociala medier.</p>
        <div class="social-media-icons">
          <a href="https://www.facebook.com/storyspot.se" target="_blank"><md-icon class="fa fa-facebook"></md-icon></a>
          <a href="https://www.instagram.com/storyspot_app" target="_blank"><md-icon class="fa fa-instagram"></md-icon></a>
          <a href="https://www.linkedin.com/company/storyspot/?originalSubdomain=se" target="_blank"><md-icon class="fa fa-linkedin"></md-icon></a>
          <a href="https://twitter.com/storyspot" target="_blank"><md-icon class="fa fa-twitter"></md-icon></a>
          <a href="https://www.mynewsdesk.com/se/storyspot" target="_blank"><md-icon class="fa fa-newspaper-o"></md-icon></a>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="link-list">
        <div class="md-layout md-gutter md-alignment">
          <div class="md-layout-item md-size-25 md-small-size-50">
            <strong>Kontakt</strong>
            <hr>
            <ol>
              <li>Mail: <a href="mailto: info@storyspot.se">info@storyspot.se</a></li>
              <li class="no-hover">Tel: 08-520 278 86</li>
              <li class="no-hover">Besöksadress:</li>
              <li class="no-hover">Kungsgatan 64</li>
              <li class="no-hover">111 22 Stockholm</li>
            </ol>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <strong>Navigation</strong>
            <hr>
            <ol>
              <li><router-link to="/dashboard">Hem</router-link></li>
              <li><router-link to="/our-storyspots">Våra storyspots</router-link></li>
              <li><router-link to="/our-users">Användare</router-link></li>
              <li><router-link to="/profile">Profil</router-link></li>
              <!-- <li><router-link to="/faq">FAQ</router-link></li>
                   <li><router-link to="/support">Support</router-link></li>
                   <li><router-link to="/terms-and-conditions">Terms & conditions</router-link></li>
                   <li><router-link to="/feedback">Feedback</router-link></li> -->
            </ol>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <strong>Hitta oss</strong>
            <hr>
            <ol>
              <li><a href="https://apps.apple.com/se/app/storyspot-discover-the-world/id1090760665?l=en" target="_blank">App store</a></li>
              <li><a href="https://play.google.com/store/apps/details?id=se.storyspot.android.StorySpot&hl=sv&gl=US&pli=1" target="_blank">Google play</a></li>
            </ol>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss" scoped>
.footer-media {
  background-color: #F0F8FF;
}

.social-media {
  height: 60px;
  max-width: 1140px;
  background-color: #F0F8FF;
  display: flex;
  align-items: center;
  margin: auto;

}

strong {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: white !important;
}

.link-list {
  margin: auto;
  max-width: 1140px;
  margin-top: 25px;
}

.intro-text {
  width: 296px;
  font-weight: lighter;
  font-size: 14px;
  line-height: 14px;
  color: #002543;
  margin: auto 0 auto 0;
}
.social-content {
  max-width: 1140px;
}
.social-media-icons {
  margin: auto 0 auto auto;
}

.social-media-icons .md-icon {
  color: #004985;
  width: 50px;
  margin: auto 0 auto 0;
}

ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 500;

}
ol li {
  font-size: 14px;
  color: #F2F6F9;
}
ol a {
  font-size: 14px;
  color: #F2F6F9 !important;
}
ol a:hover {
  color: red;
}
a .md-icon:hover {
  color: #A33E00;
}
hr {
  margin-left: 0;
  max-width: 200px;
}

.footer li:hover {
  text-decoration: underline;
}

.no-hover {
  text-decoration: none !important;
  pointer-events: none;
}
</style>
